import { Link } from 'react-router';
import ProjectCardActions from '../ProjectCardActions';
import './project-card-listing.css';
import type { User } from '~/models/user.server';
import type { Project } from '~/models/project.server';
import { IconLock } from '@fastly/beacon-icons';

type ProjectCardListingProps = {
  description?: string;
  featured?: boolean;
  href: string;
  id: Project['id'];
  imgSrc: string;
  isPrivate?: boolean;
  isUser?: boolean;
  pinned?: boolean;
  showDescription?: boolean;
  title?: string;
  userId?: User['id'];
};

const ProjectCardListing = ({
  description,
  featured,
  href,
  id,
  imgSrc,
  isPrivate,
  isUser,
  pinned,
  showDescription = false,
  title,
  userId,
}: ProjectCardListingProps) => {
  return (
    <div className="project-card-listing-container">
      <Link to={href} className="project-card-listing">
        <div className="project-card-listing-img-wrapper">
          {imgSrc !== '' && (
            <img className="project-card-listing-img" src={imgSrc} alt="" />
          )}
        </div>
        {title && (
          <div className="project-card-listing-content-container">
            {isPrivate && <IconLock />}
            <div>
              <p className="project-card-listing-title">{title}</p>
              {showDescription && description && (
                <p className="project-card-listing-description">
                  {description}
                </p>
              )}
            </div>
          </div>
        )}
      </Link>
      {isUser && (
        <ProjectCardActions
          userId={userId}
          projectId={id}
          pinned={pinned}
          featured={featured}
        />
      )}
    </div>
  );
};

export default ProjectCardListing;
